
import { Watch,Mixins,Component,Vue,ProvideReactive } from "vue-property-decorator";
import PrescriptionProductListCom from "./components/productList.vue";

import { getPresCategoryList } from "@/api/prescriptionDb"

import { cloneDeep } from "lodash";

@Component({
    inject: [],
    components: {
        PrescriptionProductListCom
    }
})
export default class PrescriptionMerchantProductList extends Vue {

    parentId = 1

    productTypeOptions = [{ label: "项目产品库",value: 1 },{ label: "居家产品库",value: 2 },{ label: "其他库",value: 3 }]

    categoryList = [];

    @ProvideReactive()
    merchantId = this.$route.query.merchantId || 0;

    @ProvideReactive()
    categoryInfo = {} as any;

    categoryLoading = false;

    mounted() {
        this.getData();
    }
    handleClick($event) {
        this.getData();
    }

    getData() {
        this.categoryLoading = true;
        getPresCategoryList({ merchantId: this.merchantId,parentId: this.parentId }).then((res) => {
            this.categoryList = res.data;
            this.categoryInfo = res.data[0];
        }).finally(() => {
            this.categoryLoading = false;
        })
    }

    getProductList(category) {
        this.categoryInfo = cloneDeep(category);
    }

}
